import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const integrationSummaryActionName = 'INTEGRATION_SUMMARY';


const integrationSummaryService = {
    get: ({integrationId}) => {
        return Api(`/integration/summary/${integrationId}`, {
            method: "GET",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const IntegrationSummaryServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_INTEGRATION_SUMMARY_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(integrationSummaryService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(integrationSummaryActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(integrationSummaryActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_INTEGRATION_SUMMARY_SUCCESS`: {
            return {
                ...state,
                ...action.payload,
                status: apiStatus.SUCCESS,
            };
        }
        default: {
            return state;
        }
    }
};
