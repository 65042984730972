import axios from "axios";
import hostname from "./services/hostname";
import storage from "./storage";
import {login} from "./services/gatewayApi";

const TOKEN_STORAGE_KEY = "SP_SecureBusiness";
const GATEWAY_UNAUTHORISED_CODE = 401;

const Api = axios.create({
  // baseURL: "https://st-poynt.shoppinpal.com/",
  baseURL: hostname,
});

// Attach token in header
Api.interceptors.request.use(
  (config) => {
      config.headers.token = storage.get(TOKEN_STORAGE_KEY);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Get token if gateway sends unauthorised and retry request
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === GATEWAY_UNAUTHORISED_CODE) {
      return login()
        .then((authResponse) => {
          storage.set(TOKEN_STORAGE_KEY, authResponse.token);
          return Promise.resolve();
        })
        .then(() => {
          return Api.request(error.config);
        });
    }

    return Promise.reject(error);
  }
);

export default Api;
