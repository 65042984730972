import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const chartAccountActionName = 'CHART_ACCOUNT';

const charAccountService = {
    get: ({ accountId }) => {
        const url = `/accounting/${accountId}/chart-account`;
        return Api(url, {
            method: 'GET',
        });
    },
};

const initialState = {
    status: apiStatus.INIT,
    data: [],
};

export const ChartAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_CHART_ACCOUNT_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(charAccountService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(chartAccountActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(chartAccountActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_CHART_ACCOUNT_SUCCESS`: {
                return {
                    ...state,
                    ...action.payload,
                    status: apiStatus.SUCCESS,
                }
        }
        case `RESET_CHART_ACCOUNT`: {
                return initialState
        }
        default: {
            return state;
        }
    }
};
