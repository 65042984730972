export default {
    home: {
        current: '/',
        next: '/account-mapping',
    },
    qbd_setup_0: {
        current: '/qbd-setup',
        step: 0,
        next: '/account-mapping',
        context: 'qbd setup started',
    },
    qbd_setup_1: {
        current: '/qbd-setup',
        step: 1,
        next: '/account-mapping',
        context: 'qbd setup started',
    },
    qbd_setup_2: {
        current: '/qbd-setup',
        step: 2,
        next: '/account-mapping',
    },
    qbd_setup_3: {
        current: '/qbd-setup',
        step: 3,
        next: '/account-mapping',
    },
    account_mapping_step_1: {
        current: '/account-mapping',
        step: 1,
        context: 'initial setup',
    },
    account_mapping_step_2: {
        current: '/account-mapping',
        step: 2,
        context: 'test sync done!',
    },
    account_mapping_step_3: {
        current: '/account-mapping',
        step: 3,
        context: 'date sync',
    },
    account_mapping_step_4: {
        current: '/account-mapping',
        step: 4,
        context: 'edit setttings',
    },
    disconnect_sync_qbo: {
        current: '/disconnect',
        context: 'disconnect qbo sync',
    },
    disconnect_sync_qbo_go_home: {
        current: '/',
        context: 'disconnect qbo sync clicked on homepage',
    },
    summary: {
        current: '/summary',
    },
}
