import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const selectedChartAccountActionName = 'SELECTED_CHART_ACCOUNT';

const selectedChartAccountService = {
    get: ({destinationName, accountId, mid}) => {
        const url = {
            qbo: `/accounting/${destinationName}/${accountId}/details`,
            qbd: `/accounting/${destinationName}/${mid}`
        }
        return Api(url[destinationName], {
            method: "GET",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
    Account: {},
};

export const SelectedChartAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_SELECTED_CHART_ACCOUNT_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(selectedChartAccountService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(selectedChartAccountActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(selectedChartAccountActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_SELECTED_CHART_ACCOUNT_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        case `RESET_SELECTED_CHART_ACCOUNT`: {
            return initialState
        }
        default: {
            return state;
        }
    }
};
