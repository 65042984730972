import React from 'react';
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import QBOButton from '../../assets/images/connect-to-quickbooks.svg';
import disconnectStyles from "./disconnectStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import Button from "@material-ui/core/Button";
import {ANALYTICS_BODY} from "../../helpers/constants";
import parseQueryParams from "../../helpers/parseQueryParams";

class Disconnect extends React.Component {
    componentDidMount() {
        const {handshake, integrationProgress, businessId} = this.props;
        const {identity: {poyntId}} = integrationProgressHelper(integrationProgress);
        if (businessId && !poyntId) {
            handshake({businessId});
        }
    }

    reSyncQBO = () => {
        const {updateAnalytics, businessId, getQBOAuth} = this.props;
        updateAnalytics({
            ...ANALYTICS_BODY.reconnect_account,
            id: businessId,
        })
        getQBOAuth({businessId}).then(() => {
            this.openOauthWindow();
        })
    }

    listenFunction = (event) => {
        if (event.data === 'oauth-done') {
            this.props.resetQBOAuth();
            const {businessId, getIntegrationProgress} = this.props;
            getIntegrationProgress({
                businessId
            })
            window.removeEventListener('message', this.listenFunction, true);
        }
    }

    openOauthWindow() {
        const {oauthURL: {authUrl}} = this.props;
        window.addEventListener('message', this.listenFunction, true);
        window.open(authUrl, "externalWindow", "width=900,height=700,left=200,top=200");
    }

    updateProgress = () => {
        const {
            integrationProgress,
            postIntegrationProgress,
            history
        } = this.props;
        const {identity: {businessId}} = integrationProgressHelper(integrationProgress);
        const {gdphub} = parseQueryParams();
        postIntegrationProgress({
            businessId,
            progress: integrationProgressConstants.disconnect_sync_qbo_go_home,
        }).then(() => {
            history.push({
                pathname: '/',
                search: `?businessId=${businessId}${gdphub === "true" ? `&gdphub=${gdphub}` : ''}`,
            })
        })
    }

    render() {
        const {classes} = this.props;
        return (<Card>
            <CardContent>
                <div className={classes.disconnectContainer}>
                    <Box mt={7} mb={3}>
                        <Typography variant="h5">QuickBooks Disconnected.</Typography>
                    </Box>
                    <Box my={3}>
                        <Typography variant="subtitle2" className={classes.description} component="p">
                            Your QuickBooks integration has been disconnected. You will no longer to be able to send
                            invoices directly to your QuickBooks account from Accounting Sync.
                            If you’d like to re-connect Accounting Sync and your QuickBooks account.
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Button className={classes.link} onClick={this.updateProgress}>Go to homepage</Button>
                    </Box>
                    <Box mt={2}>
                        <img
                            alt="qbo button"
                            src={QBOButton}
                            className={classes.imageButton}
                            onClick={this.reSyncQBO}/>
                    </Box>
                </div>
            </CardContent>
        </Card>)
    }
}


Disconnect.propTypes = {};

export default withStyles(disconnectStyles)(Disconnect);
