import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const analyticsActionName = 'ANALYTICS';

const AnalyticsService = {
    post: (data) => {
        return Api(`/analytics/track`, {
            method: "POST",
            data:data
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const AnalyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `POST_ANALYTICS_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(AnalyticsService.post, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(analyticsActionName, httpActions.postSuccess, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(analyticsActionName, httpActions.postFailed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `POST_ANALYTICS_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        default: {
            return state;
        }
    }
};
