import {Cmd, loop} from "redux-loop";
import {actionDispatcher, apiStatus, dispatchContext, httpActions,} from "./generator";
import Api from "../Api";

export const integrationProgressActionName = "INTEGRATION_PROGRESS";

const integrationProgressService = {
    get: ({businessId}) => {
        const url = `/integration/state?businessId=${businessId}`;
        return Api(url, {
            method: "GET",
        });
    },
    post: ({businessId, requestBody}) => {
        const url = `/integration/state?businessId=${businessId}`;
        return Api(url, {
            method: "POST",
            data: requestBody,
        });
    },
};

const initialState = {
    status: apiStatus.INIT,
    postStatus: apiStatus.INIT,
};

export const IntegrationProgressReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_INTEGRATION_PROGRESS_INIT`: {
            return loop(
                {...state, status: apiStatus.INIT},
                Cmd.run(integrationProgressService.get, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.success,
                                payload
                            )
                        ),
                    failActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.failed,
                                payload
                            )
                        ),
                    args: [action.payload],
                })
            );
        }
        case `GET_INTEGRATION_PROGRESS_SILENTLY`: {
            return loop(
                state,
                Cmd.run(integrationProgressService.get, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.successSilent,
                                payload
                            )
                        ),
                    failActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.failed,
                                payload
                            )
                        ),
                    args: [action.payload],
                })
            );
        }
        case `GET_INTEGRATION_PROGRESS_SILENT_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
            };
        }
        case `GET_INTEGRATION_PROGRESS_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        case `POST_INTEGRATION_PROGRESS_INIT`: {
            return loop(
                {
                    ...state,
                    postStatus: apiStatus.INIT,
                },
                Cmd.run(integrationProgressService.post, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.postSuccess,
                                payload
                            )
                        ),
                    failActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.postFailed,
                                payload
                            )
                        ),
                    args: [action.payload],
                })
            );
        }
        case `POST_INTEGRATION_PROGRESS_SUCCESS`: {
            return {
                ...state,
                postStatus: apiStatus.SUCCESS,
            };
        }
        case `POST_INTEGRATION_PROGRESS_INIT_CUSTOM`: {
            let payload = {};
            if (state.data && state.data.identity) {
                payload = {
                    businessId: state.data.identity.businessId,
                    requestBody: {
                        data: {
                            identity: {
                                ...state.data.identity,
                                ...action.payload.identity,
                            },
                            progress: {
                                ...state.data.progress,
                                ...action.payload.progress,
                            },
                        },
                    },
                };
            } else {
                payload = {
                    businessId: action.payload.identity.businessId,
                    requestBody: {
                        data: {
                            identity: {
                                ...action.payload.identity,
                            },
                            progress: {
                                ...action.payload.progress,
                            },
                        },
                    },
                };
            }
            return loop(
                state,
                Cmd.run(integrationProgressService.post, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.postSuccessCustom,
                                payload
                            )
                        ),
                    failActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.postFailed,
                                payload
                            )
                        ),
                    args: [payload],
                })
            );
        }
        case `POST_INTEGRATION_PROGRESS_SUCCESS_CUSTOM`: {
            // window.opener.postMessage("oauth-done", "*");
            // window.close();
            return state;
        }
        case `POST_INTEGRATION_PROGRESS_INIT_PARTIAL_UPDATE`: {
            let payload = {};
            if (state.data && state.data.identity) {
                payload = {
                    businessId: state.data.identity.businessId,
                    requestBody: {
                        data: {
                            identity: {
                                ...state.data.identity,
                                ...action.payload.identity,
                            },
                            progress: {
                                ...state.data.progress,
                                ...action.payload.progress,
                            },
                        },
                    },
                };
            } else {
                payload = {
                    businessId: action.payload.identity.businessId,
                    requestBody: {
                        data: {
                            identity: {
                                ...action.payload.identity,
                            },
                            progress: {
                                ...action.payload.progress,
                            },
                        },
                    },
                };
            }
            return loop(
                state,
                Cmd.run(integrationProgressService.post, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(
                                integrationProgressActionName,
                                httpActions.postPartialCustomSuccess,
                                payload
                            )
                        ),
                    failActionCreator: (payload) =>
                        actionDispatcher(
                            integrationProgressActionName,
                            dispatchContext(httpActions.postFailed, payload)
                        ),
                    args: [payload],
                })
            );
        }
        case `POST_INTEGRATION_PROGRESS_SUCCESS_PARTIAL_UPDATE`: {
            return state;
        }
        default: {
            return state;
        }
    }
};
