import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";
import {integrationProgressActionName} from "./IntegrationProgress.reducer";

export const midServiceActionName = 'MID';

const midService = {
    get: ({businessId}) => {
        const url = `/poynt/get-mid/${businessId}`;
        return Api(url, {
            method: 'GET',
        });
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const MIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_MID_INIT`: {
            return loop({...state, status: apiStatus.INIT},
                Cmd.run(midService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(midServiceActionName, httpActions.success, {
                        previousPayload: action.payload,
                        payload
                    })),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(midServiceActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_MID_SUCCESS`: {
            const postPayload = {
                identity: {
                    ...action.payload.payload.data
                }
            }
            return loop(
                {
                    ...state,
                    ...action.payload.payload.data,
                    status: apiStatus.SUCCESS
                },
                Cmd.list([
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, postPayload))),
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, {businessId: action.payload.previousPayload.businessId}))),
                ], {sequence: true})
            )
        }
        case `GET_MID_FAILED`: {
            console.log('came here');
            return state;
        }
        default: {
            return state;
        }
    }
};
