import intuitLogo from '../assets/images/QB_IntuitLogo.png';
import qbdScreen from '../assets/images/qbd.png';
import qboScreen from '../assets/images/quickbooks_image_@3x.png';

export const qbdSetupConstants = {
  title: 'QuickBooks Desktop',
  description: "Connect your Poynt account with your Windows based QuickBooks Desktop application. This will allow you to sync your payments, product and transactions, eliminating the need for manual data entry.",
  intuitLogo,
  screen: qbdScreen,
};

export const qboSetupConstants = {
  title: 'QuickBooks Online',
  description: 'Connect your Poynt account with your QuickBooks Online application. This will allow you to sync your payments, product and transactions, eliminating the need for manual data entry.',
  intuitLogo,
  screen: qboScreen,
};

export const ACCOUNT_MAPPING = [
  {
    CATEGORY: 'Income Account',
    DESCRIPTION: 'All sales done via the Poynt device are synced as Income items. Only Settled transactions will be synced.',
    TYPE: 'Income',
    FIELD: [],
    STATUS: 'Synced',
    DROPDOWN: true,
  },
  {
    CATEGORY: 'Poynt Taxes',
    DESCRIPTION: 'This header is used to give the sum total of all taxes received in a given transaction. ',
    TYPE: 'Income ',
    FIELD: 'Poynt Taxes',
    STATUS: 'Synced',
  },
  {
    CATEGORY: 'Poynt Tips',
    DESCRIPTION: 'This header is used to pass information on tips collected via your Poynt device.',
    TYPE: 'Other charge',
    FIELD: 'Poynt Tips',
    STATUS: 'Synced',
  },
  {
    CATEGORY: 'Poynt Discounts',
    DESCRIPTION: 'This is the sum total of all discounts added in a given transaction ',
    TYPE: 'Discount',
    FIELD: 'Poynt Discounts',
    STATUS: 'Synced',
  },
  {
    CATEGORY: 'Poynt Fees',
    DESCRIPTION: 'This is the sum total of all discounts added in a given transaction ',
    TYPE: 'Other charge',
    FIELD: 'Poynt Fees',
    STATUS: 'Synced',
  },
];

export const ANALYTICS_BODY = {
  start_accounting_sync: {
    eventName: 'start_accounting_sync',
    id: '1',
    events: {
      desc: 'qbo',
    },
  },
  start_qbo_accounting_sync: {
    eventName: 'start_qbo_accounting_sync',
    id: '1',
    events: {
      desc: 'qbo',
    },
  },
  start_qbd_accounting_sync: {
    eventName: 'start_qbd_accounting_sync',
    id: '1',
    events: {
      desc: 'qbd',
    },
  },
  qbd_setup_1: {
    eventName: 'qbd_setup_1',
    events: {
      desc: 'acknowledged',
    },
  },
  qbd_setup_2: {
    eventName: 'qbd_setup_2',
    events: {
      desc: 'downloaded',
    },
  },
  qbd_setup_3: {
    eventName: 'qbd_setup_3',
    events: {
      desc: 'acknowledged',
    },
  },
  qbd_setup_4: {
    eventName: 'qbd_setup_4',
    events: {
      desc: 'acknowledged',
    },
  },
  qbd_scheduled_sync: {
    eventName: 'qbd_scheduled_sync',
    id: '13',
    events: {
      desc: 'connected_sync',
    },
  },
  disconnected_sync_qbd: {
    eventName: 'disconnected_sync_qbd',
    id: '8',
    events: {
      desc: 'disconnected_sync',
    },
  },
  qbo_test_sync: {
    eventName: 'qbo_test_sync',
    id: '9',
    events: {
      desc: 'qbo_test_sync',
    },
  },
  qbd_test_sync: {
    eventName: 'qbd_test_sync',
    id: '19',
    events: {
      desc: 'qbd_test_sync',
    },
  },
  disconnected_sync_qbo: {
    eventName: 'disconnected_sync_qbo',
    id: '10',
    events: {
      desc: 'disconnected_sync',
    },
  },
  qbo_scheduled_sync: {
    eventName: 'qbo_scheduled_sync',
    id: '11',
    events: {
      desc: 'scheduled_sync',
    },
  },
  reconnect_account: {
    eventName: 'reconnect_account',
    id: '12',
    events: {
      desc: 'qbo',
    },
  },
  qbo_connected_sync: {
    eventName: 'qbo_connected_sync',
    id: '14',
    events: {
      desc: 'connected_sync',
    },
  },
  qbo_enforce_sync: {
    eventName: 'qbo_enforce_sync',
    id: '15',
    events: {
      desc: 'qbo_enforce_sync',
    },
  },
  qbd_enforce_sync: {
    eventName: 'qbd_enforce_sync',
    id: '16',
    events: {
      desc: 'qbo_enforce_sync',
    },
  },
  qbd_setup_cancelled_at_step_1: {
    eventName: 'qbd_setup_cancelled_at_step_1',
    events: {
      desc: 'qbd_setup_cancel',
    },
  },
  qbd_setup_cancelled_at_step_2: {
    eventName: 'qbd_setup_cancelled_at_step_2',
    events: {
      desc: 'qbd_setup_cancel',
    },
  },
  qbd_setup_cancelled_at_step_3: {
    eventName: 'qbd_setup_cancelled_at_step_3',
    events: {
      desc: 'qbd_setup_cancel',
    },
  },
  qbd_setup_cancelled_at_step_4: {
    eventName: 'qbd_setup_cancelled_at_step_4',
    events: {
      desc: 'qbd_setup_cancel',
    },
  },
};
