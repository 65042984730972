import {connect} from "react-redux";
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import OAuth from "./OAuth";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import {activateIntegrationActionName} from "../../appstate/ActivateIntegration.reducer";


const mapStateToProps = (state) => ({
    handshake: state.handshake,
});

const mapDispatchToProps = (dispatch) => ({
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.get, payload))),
    postIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postCustom, payload))),
    activateIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(activateIntegrationActionName, httpActions.post, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(OAuth);

// https://spp.poynt.net/#/oauth?businessId=63028e83-1da0-4d16-bc49-a5620690d359&accountId=65dce7753547f26f2cfb7dae&businessId=63028e83-1da0-4d16-bc49-a5620690d359&qboAccountId=65dce7758ce745797b1f46a7&isNewQboAccount=false&mappingExists=false