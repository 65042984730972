import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";
import {integrationProgressActionName} from "./IntegrationProgress.reducer";
import integrationProgressConstants from "../helpers/integrationProgressConstants";
import downloadFile from "../helpers/downloadFile";

export const downloadQBDFileActionName = 'DOWNLOAD_QBD_FILE';

const DownloadQBDFileService = {
    post: (data) => {
        const url = `/accounting/qbd-file-data`;
        return Api(url, {
            method: 'POST',
            data
        });
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const DownloadQBDFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case `POST_DOWNLOAD_QBD_FILE_INIT`: {
            return loop({...state, status: apiStatus.INIT},
                Cmd.run(DownloadQBDFileService.post, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(downloadQBDFileActionName, httpActions.postSuccess, {
                        previousPayload: action.payload,
                        payload
                    })),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(downloadQBDFileActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `POST_DOWNLOAD_QBD_FILE_SUCCESS`: {
            if (action.payload.payload.data.status !== 0) {
                downloadFile(new Blob([action.payload.payload.data.xmlFileData], {type: 'text/plain'}), action.payload.payload.data.data.companyName);
                const integrationUpdatePayload = {
                    businessId: action.payload.previousPayload.businessId,
                    identity: {
                        businessId: action.payload.previousPayload.businessId,
                        qbdFilePassword: action.payload.payload.data.data.password,
                        accountId: action.payload.payload.data.data.accountId,
                        xmlFileData: action.payload.payload.data.xmlFileData,
                    },
                    progress: integrationProgressConstants.qbd_setup_2,
                }
                return loop({
                    ...state,
                    ...action.payload.data
                }, Cmd.list([
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, integrationUpdatePayload))),
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, integrationUpdatePayload))),
                ], {sequence: true}));
            }
            return state;
        }
        default: {
            return state;
        }
    }
};
