export default () => ({
    success: {
        color: '#fff !important',
        backgroundColor: '#39DA91 !important',
        fontWeight: 700,
        '& svg': {
            color: '#fff',
        }
    },
    error: {
        color: '#fff!important',
        backgroundColor: '#E74C3C !important',
        fontWeight: 700,
        '& svg': {
            color: '#fff',
        }
    },
})