import React from 'react';
import parseQueryParams from "../../helpers/parseQueryParams";
import {Box} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import storage from '../../storage';

class OAuth extends React.Component {
    componentDidMount() {
        const params = parseQueryParams();
        let savedBusinessId;
        const {postIntegrationProgress, businessId, activateIntegration} = this.props;
        if (!businessId) {
            savedBusinessId = storage.get('businessId');
        } else {
            savedBusinessId = businessId;
        }
        if (savedBusinessId) {
            const progressDataObj = {
                businessId: savedBusinessId,
                identity: {
                    ...params,
                    destinationName: 'qbo',
                    businessId: savedBusinessId,
                },
                progress: this.getCurrentProgress(),
            }
            postIntegrationProgress(progressDataObj);
            activateIntegration({accountId: params.accountId});
        }
    }

    getCurrentProgress = () => {
        const {integrationProgress, fullSyncEnabled} = this.props;
        const {identity: {disconnectedProgess}} = integrationProgressHelper(integrationProgress);
        const params = parseQueryParams();
        const {isNewQboAccount} = params;
        if (fullSyncEnabled && isNewQboAccount === "false") {
            return integrationProgressConstants.summary;
        }
        if (!fullSyncEnabled && isNewQboAccount === "false") {
            if (disconnectedProgess) {
                return disconnectedProgess;
            }
        }
        return integrationProgressConstants.account_mapping_step_1;
    }


    render() {
        return (<Box style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <h1>Awaiting authentication</h1>
            <CircularProgress/>
        </Box>)
    }
}


OAuth.propTypes = {};

export default OAuth;
