export default class PageHeightMessager {
  constructor() {
    this.scrollHeight = 0;
    this.init();
  }

  checkHeight() {
    if (!document.body) return;

    const documentHeight = document.body.scrollHeight;
    if (documentHeight !== this.scrollHeight) {
        this.scrollHeight = documentHeight + 96;
      this.sendMessage(this.scrollHeight);
    }
  }

  sendMessage(height) {
    console.log('sendingMessage:', height);
    try {
      window.parent.window.parent.postMessage(
        { eventName: 'SET_HEIGHT', payload: { height } },
        '*'
      );
    } catch (err) {
      window.parent.postMessage({ eventName: 'SET_HEIGHT', payload: { height } }, '*');
    }
  }

  init() {
    window.addEventListener('load', () => {
      const observer = new MutationObserver(this.checkHeight.bind(this));
      observer.observe(document.body, {
        subtree: true,
        attributes: true,
        childList: true,
        characterData: true,
      });
    });
    window.onload = () => this.checkHeight();
  }
}
