import {connect} from "react-redux";
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import Disconnect from "./Disconnect";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import {analyticsActionName} from "../../appstate/Analytics.reducer";
import {handshakeActionName} from "../../appstate/Handshake.reducer";
import {qboAuthActionName} from "../../appstate/QBOAuth.reducer";


const mapStateToProps = (state) => ({
    integrationProgress: state.integrationProgress,
    oauthURL: state.qboAuth,
});

const mapDispatchToProps = (dispatch) => ({
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    getQBOAuth: (payload) => dispatch(actionDispatcher(dispatchContext(qboAuthActionName, httpActions.get, payload))),
    handshake: (payload) => dispatch(actionDispatcher(dispatchContext(handshakeActionName, httpActions.get, payload))),
    postIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
    resetQBOAuth: (payload) => dispatch(actionDispatcher(dispatchContext(qboAuthActionName, httpActions.reset, payload))),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Disconnect);
