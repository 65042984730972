export default () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginBottom16: {
        marginBottom: '16px',
    },
    companyImage: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    imageContain: {
        objectFit: 'contain',
        maxWidth: '120px',
        width: '100%',
    },
    centeredImageContent: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    wideButton: {
        minWidth: '220px',
    },
    mediumWideButton: {
        minWidth: '180px',
    },
    danger: {
        backgroundColor: '#dc3545',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#dc3545',
            color: '#fff',
        },
    },
    centeredContent: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    spaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
    },
    linksContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
    },
    bottomActions: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        background: '#fff',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '2px solid rgba(0,0,0,.125)',
        alignItems: 'center',
        '& h6': {
            marginRight: '16px',
        },
    },
    marginRight: {
        marginRight: '16px',
    },
    picker: {
        width: '160px',
        margin: '0 8px',
    },
    setupHead: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})
