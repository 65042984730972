import QDBSetup from './QBDSetup';
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import {analyticsActionName} from "../../appstate/Analytics.reducer";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import qbdStyles from "./qbdStyles";
import {downloadQBDFileActionName} from "../../appstate/DownloadQBDFile.reducer";
import {midServiceActionName} from "../../appstate/MID.reducer";
import {chartAccountActionName} from "../../appstate/ChartAccounts.reducer";
import {toggleSnackbar} from "../../appstate/Snackbar.reducer";


const mapStateToProps = (state) => ({
    midData: state.mid,
    chartAccount: state.chartAccount.data,
});

const mapDispatchToProps = (dispatch) => ({
    toggleSnackbar: (payload) => dispatch(toggleSnackbar(payload)),
    getChartAccount: (payload) => dispatch(actionDispatcher(dispatchContext(chartAccountActionName, httpActions.get, payload))),
    getMID: (payload) => dispatch(actionDispatcher(dispatchContext(midServiceActionName, httpActions.get, payload))),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    postPartialIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
    postIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.post, payload))),
    downloadQBDFile: (payload) => dispatch(actionDispatcher(dispatchContext(downloadQBDFileActionName, httpActions.post, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(qbdStyles)(QDBSetup));
