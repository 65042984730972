import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const poyntLatestSyncActionName = 'POYNT_LATEST_SYNC';

const poyntLatestSyncService = {
    get: ({poyntId}) => {
        return Api(`/poynt/get/latest-sync/${poyntId}`, {
            method: "GET",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const PoyntLatestSyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_POYNT_LATEST_SYNC_INIT`: {
            return loop({ ...state, status: apiStatus.INIT},
                Cmd.run(poyntLatestSyncService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(poyntLatestSyncActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(poyntLatestSyncActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_POYNT_LATEST_SYNC_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS
            }
        }
        default: {
            return state;
        }
    }
};
