import get from 'lodash/get';
import integrationProgressConstants from "./integrationProgressConstants";

export default (integrationProgress) => {
    const identity = get(integrationProgress, 'data.identity', {});
    const progress = get(integrationProgress, 'data.progress', integrationProgressConstants.home);
    return {
        identity,
        progress: Object.keys(progress).length > 0 ? progress : integrationProgressConstants.home,
    }
}