const initialState = {
    open: false,
    message: '',
    autoHideDuration: 2000,
};

export const toggleSnackbar = (payload) => ({
    type: 'TOGGLE_SNACKBAR',
    payload
})

export const SnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_SNACKBAR': {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};
