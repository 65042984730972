import {combineReducers} from 'redux-loop';
import {IntegrationProgressReducer} from "./IntegrationProgress.reducer";
import {AnalyticsReducer} from "./Analytics.reducer";
import {HandshakeReducer} from "./Handshake.reducer";
import {QBOAuthReducer} from "./QBOAuth.reducer";
import {ChartAccountReducer} from "./ChartAccounts.reducer";
import {MIDReducer} from "./MID.reducer";
import {IntegrationsReducer} from "./Integration.reducer";
import {SaveDetailsReducer} from "./SaveDetails.reducer";
import {SelectedChartAccountReducer} from "./SelectedChartAccount.reducer";
import {PoyntSyncReducer} from "./PoyntSync.reducer";
import {IntegrationSummaryServiceReducer} from "./IntegrationSummary.reducer";
import {PoyntLatestSyncReducer} from "./PoyntLatestSync.reducer";
import {DeactivateIntegrationReducer} from "./DeactivateIntegration.reducer";
import {DeactivateSyncReducer} from "./DeactivateSync.reducer";
import {QBODisconnectReducer} from "./QBODisconnect.reducer";
import {DownloadQBDFileReducer} from "./DownloadQBDFile.reducer";
import {SnackbarReducer} from "./Snackbar.reducer";
import {IdentifyReducer} from "./Identity.reducer";
import {ActivateIntegrationReducer} from "./ActivateIntegration.reducer";

export default combineReducers({
  integrationProgress: IntegrationProgressReducer,
  analytics: AnalyticsReducer,
  handshake: HandshakeReducer,
  qboAuth: QBOAuthReducer,
  qboDisconnect: QBODisconnectReducer,
  chartAccount: ChartAccountReducer,
  mid: MIDReducer,
  integration: IntegrationsReducer,
  saveDetails: SaveDetailsReducer,
  selectedChartAccount: SelectedChartAccountReducer,
  poyntSync: PoyntSyncReducer,
  poyntLatestSync: PoyntLatestSyncReducer,
  integrationSummary: IntegrationSummaryServiceReducer,
  deactivateIntegration: DeactivateIntegrationReducer,
  deactivateSync: DeactivateSyncReducer,
  downloadQBDFile: DownloadQBDFileReducer,
  snackbar: SnackbarReducer,
  identify: IdentifyReducer,
  activateIntegrations: ActivateIntegrationReducer,
});
