import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import stepConstants from './stepConstants';
import Grid from "@material-ui/core/Grid";
import YouTube from "react-youtube";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepContent: {
        textAlign: 'center',
    },
    buttonMinWidth: {
        minWidth: '180px',
        textTransform: 'capitalize',
        fontSize: '16px',
    },
    youtube: {
        display: 'flex',
        justifyContent: 'center'
    },
    stepButton: {
        padding: 0,
        margin: 0,

        '& svg.MuiStepIcon-active': {
            color: '#4da6ff !important',
        }
    },
    partialActive: {
        '& svg.MuiStepIcon-root': {
            color: '#bcddff',
        }
    },
    disabled: {
        cursor: 'not-allowed',
    },
    enabled: {
        cursor: 'pointer',
    },
    customH5: {
        fontSize: '2.4rem',
        lineHeight: 1.5
    },
}));


const onReady = (event) => {
    event.target.pauseVideo();
}


export default ({activeStep, qbdActions, customQBDContent, qbdStepper, allStepsActive}) => {
    const qbdactions = qbdActions();
    const customContent = customQBDContent();
    const classes = useStyles();

    const {
        title, description, actionLabel, videoId,
    } = stepConstants[activeStep];

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                        {stepConstants.map((label, index) => (
                            <Step key={label}>
                                <StepButton
                                    disableRipple
                                    onClick={() => allStepsActive ? qbdStepper(index) : null}
                                    className={clsx({
                                        [classes.stepButton]: true,
                                        [classes.disabled]: !allStepsActive,
                                        [classes.partialActive]: allStepsActive,
                                        [classes.enabled]: index === 0
                                    })}
                                />
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} style={{padding: '5% 0'}}>
                    <Box className={classes.stepContent}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            className={classes.customH5}
                        >
                            {title}
                        </Typography>
                        {description &&  <Typography
                            gutterBottom
                            variant="subtitle1"
                            component="p"
                            style={{color: '#808080'}}
                        >
                            {description}
                        </Typography>}
                        {customContent[`step${activeStep}`] && <Box>
                            {customContent[`step${activeStep}`]}
                        </Box>}
                        <Box my={4} style={{clear: 'both'}}>
                            <Button
                                disableElevation
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={qbdactions[`step${activeStep}`]}
                                className={classes.buttonMinWidth}
                            >
                                {actionLabel}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box p={3} className={classes.youtube}>
                        <YouTube
                            opts={{
                                height: '250',
                                width: '460',
                            }}
                            className="videoGuide"
                            videoId={videoId}
                            onReady={onReady}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
