import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const deactivateSyncActionName = 'DEACTIVATE_SYNC';

const deactivateSyncService = {
    get: ({businessId, syncValue}) => {
        return Api(`/poynt/deactivate-sync?businessId=${businessId}&active=${syncValue}`, {
            method: "GET",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
    Account: {
        incomeAccountRef: ''
    },
};

export const DeactivateSyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_DEACTIVATE_SYNC_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(deactivateSyncService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(deactivateSyncActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(deactivateSyncActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_DEACTIVATE_SYNC_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        default: {
            return state;
        }
    }
};
