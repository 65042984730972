export default () => ({
    mediumWideButton: {
        minWidth: '180px',
    },
    bottomActions: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        background: '#fff',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '2px solid rgba(0,0,0,.125)',
        alignItems: 'center',
        '& h6': {
            marginRight: '16px',
        },
    },
    marginRight: {
        marginRight: '16px',
    },
    picker: {
        width: '160px',
        margin: '0 8px',
    }
})