export default () => ({
    imageButton: {
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '15px',
        width: '125px',
        height: '40px',
    },
    disconnectContainer: {
        textAlign: 'center',
        padding: '3% 0',
        margin: '0 auto',
    },
    description: {
        color: '#808080',
        lineHeight: 1.8,
        fontSize: '16px',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        textTransform: 'none',
        fontSize: '16px',
        color: '#808080',
        '&:hover': {
            color: '#2544B7',
            textDecoration: 'underline',
            background: 'none',
        }
    }

})