import { Cmd, loop } from "redux-loop";
import {
  actionDispatcher,
  apiStatus,
  dispatchContext,
  httpActions,
} from "./generator";
import Api from "../Api";
import { integrationProgressActionName } from "./IntegrationProgress.reducer";
import storage from "../storage";
export const handshakeActionName = "HANDSHAKE";

const HandshakeService = {
  get: ({ businessId }) => {
    const url = `/poynt/handshake/?businessId=${businessId}`;
    return Api(url, {
      method: "GET",
    });
  },
};

const initialState = {
  status: apiStatus.INIT,
};

export const HandshakeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_HANDSHAKE_INIT`: {
      return loop(
        { ...state, status: apiStatus.INIT },
        Cmd.run(HandshakeService.get, {
          successActionCreator: (payload) =>
            actionDispatcher(
              dispatchContext(handshakeActionName, httpActions.success, payload)
            ),
          failActionCreator: (payload) =>
            actionDispatcher(
              dispatchContext(handshakeActionName, httpActions.failed, payload)
            ),
          args: [action.payload],
        })
      );
    }
    case `GET_HANDSHAKE_SUCCESS`: {
      const postPayload = {
        businessId: action.payload.data.businessId,
        identity: {
          businessId: action.payload.data.businessId,
          poyntId: action.payload.data.accountId,
        },
      };
      storage.set("poyntId", action.payload.data.accountId);
      return loop(
        {
          ...state,
          businessId: action.payload.data.businessId,
          poyntId: action.payload.data.accountId,
          status: apiStatus.SUCCESS,
        },
        Cmd.list([
          Cmd.action(
            actionDispatcher(
              dispatchContext(
                integrationProgressActionName,
                httpActions.postPartialCustom,
                postPayload
              )
            )
          ),
          Cmd.action(
            actionDispatcher(
              dispatchContext(
                integrationProgressActionName,
                httpActions.getSilently,
                { businessId: action.payload.data.businessId }
              )
            )
          ),
        ])
      );
    }
    default: {
      return state;
    }
  }
};
