import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#2544B7',
      // contrastText: 'rgb(255,255,255)',
    },
    secondary: {
      main: '#007bff',
    },
  },
  typography: {
    fontFamily: ['objektiv-mk2', 'sans-serif'],
    h5: {
      fontWeight: 500,
    },
  },
});
