import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";
import {integrationProgressActionName} from "./IntegrationProgress.reducer";
import {saveDetailsActionName} from "./SaveDetails.reducer";
import integrationProgressConstants from "../helpers/integrationProgressConstants";
import {selectedChartAccountActionName} from "./SelectedChartAccount.reducer";

export const integrationActionName = 'INTEGRATION';

const IntegrationsService = {
    post: (data) => {
        return Api(`/integration/create`, {
            method: "POST",
            data: data
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const IntegrationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `POST_INTEGRATION_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(IntegrationsService.post, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(integrationActionName, httpActions.postSuccess, {previousPayload: action.payload, payload})),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(integrationActionName, httpActions.postFailed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `POST_INTEGRATION_SUCCESS`: {
            const {destinationName, accountId, selectedChart, businessId, mid}= action.payload.previousPayload;
            const {_id}= action.payload.payload.data;
            const payload = {
                mid,
                destinationName,
                accountId,
                requestBody: {
                    incomeAccountRef: selectedChart,
                }
            }
            const integrationUpdatePayload = {
                identity: {
                  integrationId: _id,
                },
                progress: {
                    ...integrationProgressConstants.account_mapping_step_2
                }
            };
            return loop(
                {
                    ...state,
                    ...action.payload.data,
                    status: apiStatus.SUCCESS
                },
                Cmd.list([
                    Cmd.action(actionDispatcher(dispatchContext(saveDetailsActionName, httpActions.post, payload))),
                    Cmd.action(actionDispatcher(dispatchContext(selectedChartAccountActionName, httpActions.get, payload))),
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, integrationUpdatePayload))),
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, {businessId}))),
                ], {
                    sequence: true,
                })
            )
        }
        default: {
            return state;
        }
    }
};
