import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const deactivateIntegrationActionName = 'DEACTIVATE_INTEGRATION';

const deactivateIntegrationService = {
    get: ({integrationId, syncValue}) => {
        return Api(`/integration/deactive-integration?integrationId=${integrationId}&active=${syncValue}`, {
            method: "POST",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
    Account: {
        incomeAccountRef: ''
    },
};

export const DeactivateIntegrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_DEACTIVATE_INTEGRATION_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(deactivateIntegrationService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(deactivateIntegrationActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(deactivateIntegrationActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_DEACTIVATE_INTEGRATION_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        default: {
            return state;
        }
    }
};
