export default () => {
    const [, search] = window.location.hash.split('?');
    if(search && search.length > 0) {
        const parsedJson = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
        const {businessId} = window;
        if (businessId) {
            return {
                ...parsedJson,
                businessId,
            }
        }
        return parsedJson
    }
    const {businessId} = window;
    if (businessId) {
        return {
            businessId,
        }
    }
    return {}
}