export const apiStatus = {
    INIT: 'INIT',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    RESET: 'RESET',
};

export const httpActions = {
    get: 'get',
    getSilently: 'getSilently',
    success: 'success',
    successSilent: 'successSilent',
    failed: 'failed',
    post: 'post',
    postSuccess: 'postSuccess',
    postCustom: 'postCustom',
    postSuccessCustom: 'postSuccessCustom',
    postPartialCustom: 'postPartialCustom',
    postPartialCustomSuccess: 'postPartialCustomSuccess',
    postFailed: 'postFailed',
    reset: 'reset',
};

export const actionTriggeers = {
    get:({actionInfo: {actionName},payload}) => ({
        type: `GET_${actionName}_${apiStatus.INIT}`,
        payload,
    }),
    getSilently:({actionInfo: {actionName},payload}) => ({
        type: `GET_${actionName}_SILENTLY`,
        payload,
    }),
    success:({actionInfo: {actionName, type},payload}) => ({
        type: `GET_${actionName}_${apiStatus.SUCCESS}`,
        payload,
    }),
    successSilent:({actionInfo: {actionName, type},payload}) => ({
        type: `GET_${actionName}_SILENT_${apiStatus.SUCCESS}`,
        payload,
    }),
    failed:({actionInfo: {actionName, type},payload}) => ({
        type: `GET_${actionName}_${apiStatus.FAILED}`,
        payload,
    }),
    post:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.INIT}`,
        payload,
    }),
    postSuccess:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.SUCCESS}`,
        payload,
    }),
    postCustom:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.INIT}_CUSTOM`,
        payload,
    }),
    postSuccessCustom:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.SUCCESS}_CUSTOM`,
        payload,
    }),
    postPartialCustom:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.INIT}_PARTIAL_UPDATE`,
        payload,
    }),
    postPartialCustomSuccess:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.SUCCESS}_PARTIAL_UPDATE`,
        payload,
    }),
    postFailed:({actionInfo: {actionName, type},payload}) => ({
        type: `POST_${actionName}_${apiStatus.FAILED}`,
        payload,
    }),
    reset:({actionInfo: {actionName}}) => ({
        type: `RESET_${actionName}`,
    }),
};

export const dispatchContext = (actionName, triggerType, payload) => ({
        actionInfo: {
            actionName,
            trigger: triggerType,
        },
        payload
    })

export const actionDispatcher = (context) => actionTriggeers[context.actionInfo.trigger](context)