export default () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginBottom16: {
        marginBottom: '16px',
    },
    wideButton: {
        minWidth: '220px',
    },
    list: {
        textAlign: 'left',
        float: 'left',
    },
    password: {
        fontSize: '40px',
        color: '#2544B7',
        padding: '5px',
    },
    setupHead: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});
