export default () => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  marginBottom16: {
    marginBottom: "16px",
  },
  companyImage: {
    width: "100%",
    maxWidth: "260px",
    display: "flex",
    justifyContent: "center",
    maxHeight: "70px",
  },
  imageContain: {
    objectFit: "cover",
    maxWidth: "160px",
    maxHeight: "70px",
    width: "100%",
  },
  centeredImageContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  borderRight: {
    borderRight: "2px solid #ddd",
  },
  buttonMinWidth: {
    minWidth: "120px",
  },
  centeredContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  integrationInfoContent: {
    flexDirection: "column",
    display: "flex",
  },
  integrationInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  imageButton: {
    cursor: "pointer",
  },
});
