import AccountMapping from './AccountMapping';

import {connect} from "react-redux";
import get from "lodash/get";
import {withStyles} from "@material-ui/core";
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import accountMappingStyles from "./accountMappingStyles";
import {chartAccountActionName} from "../../appstate/ChartAccounts.reducer";
import {midServiceActionName} from "../../appstate/MID.reducer";
import {integrationActionName} from "../../appstate/Integration.reducer";
import {saveDetailsActionName} from "../../appstate/SaveDetails.reducer";
import {selectedChartAccountActionName} from "../../appstate/SelectedChartAccount.reducer";
import {qboDisconnectActionName} from "../../appstate/QBODisconnect.reducer";
import {deactivateIntegrationActionName} from "../../appstate/DeactivateIntegration.reducer";
import {analyticsActionName} from "../../appstate/Analytics.reducer";

const mapStateToProps = (state) => ({
        integrationProgress: state.integrationProgress,
        chartAccount: state.chartAccount.data,
        mid: state.mid,
        selectedChartAccount: get(state, 'selectedChartAccount.Account.incomeAccountRef', undefined),
    });

const mapDispatchToProps = (dispatch) => ({
    getChartAccount: (payload) => dispatch(actionDispatcher(dispatchContext(chartAccountActionName, httpActions.get, payload))),
    getMID: (payload) => dispatch(actionDispatcher(dispatchContext(midServiceActionName, httpActions.get, payload))),
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    postPartialIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
    createIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(integrationActionName, httpActions.post, payload))),
    saveDetails: (payload) => dispatch(actionDispatcher(dispatchContext(saveDetailsActionName, httpActions.post, payload))),
    getSelectedChartAccount: (payload) => dispatch(actionDispatcher(dispatchContext(selectedChartAccountActionName, httpActions.get, payload))),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
    qboDisconnect: (payload) => dispatch(actionDispatcher(dispatchContext(qboDisconnectActionName, httpActions.get, payload))),
    deactivateIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(deactivateIntegrationActionName, httpActions.get, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(accountMappingStyles)(AccountMapping));
