import {Route, Switch} from "react-router-dom";
import Home from "./Home";
import AccountMapping from "./AccountMapping";
import Summary from "./Summary";
import QDBSetup from "./QBDSetup";
import OAuth from "./OAuth";
import {Redirect} from "react-router";
import React from "react";
import Disconnect from "./Disconnect";
import WrapperHoc from './WrapperHOC';

const Routes = () => {
    return (
      <Switch>
        <Route exact path="/" component={WrapperHoc(Home)} />
        <Route exact path="/account-mapping" component={WrapperHoc(AccountMapping)} />
        <Route exact path="/summary" component={WrapperHoc(Summary)} />
        <Route exact path="/qbd-setup" component={WrapperHoc(QDBSetup)} />
        <Route exact path="/oauth" component={WrapperHoc(OAuth)} />
        <Route exact path="/disconnect" component={WrapperHoc(Disconnect)} />
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
}

export default Routes