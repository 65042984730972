import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import moment from "moment";
import Box from "@material-ui/core/Box";
import React from "react";
import get from 'lodash/get';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {connect} from "react-redux";
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import {integrationActionName} from "../../appstate/Integration.reducer";
import {withStyles} from "@material-ui/core";
import bottomBarStyles from "./bottomBarStyles";
import {poyntSyncActionName} from "../../appstate/PoyntSync.reducer";
import {ANALYTICS_BODY} from "../../helpers/constants";
import {analyticsActionName} from "../../appstate/Analytics.reducer";
import {toggleSnackbar} from "../../appstate/Snackbar.reducer";
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import {qboDisconnectActionName} from "../../appstate/QBODisconnect.reducer";
import {deactivateIntegrationActionName} from "../../appstate/DeactivateIntegration.reducer";

class BottomBar extends React.Component {
    state = {
        syncDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        testSyncActive: false,
        syncDataActive: false,
    }

    create = () => {
        const {
            toggleSnackbar,
            createIntegration,
            updateAnalytics,
            integrationProgress
        } = this.props;
        const {identity: {accountId, businessId, mid, destinationName, poyntId, timeZone}} = integrationProgressHelper(integrationProgress);
        this.setState({
            testSyncActive: !this.state.testSyncActive
        }, () => {
            updateAnalytics({
                ...ANALYTICS_BODY[`${destinationName}_test_sync`],
                id: businessId,
            })
            createIntegration({
                mid,
                accountId,
                businessId,
                destinationName,
                poyntId,
                timeZone,
                selectedChart: this.props.selectedChart
            }).then(() => {
                toggleSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Connected to QuickBooks! Sync will begin in the next few minutes.',
                });
            });
        })
    }

    syncData = () => {
        const {
            toggleSnackbar,
            updateAnalytics,
            poyntSync,
            integrationProgress
        } = this.props;
        const {identity: {poyntId, businessId, destinationName}} = integrationProgressHelper(integrationProgress);
        this.setState({
            syncDataActive: !this.state.syncDataActive
        }, () => {
            poyntSync({poyntId, date: this.state.syncDate, businessId});
            updateAnalytics({
                ...ANALYTICS_BODY[`${destinationName}_scheduled_sync`],
                id: businessId,
            }).then(() => {
                toggleSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Sync to QuickBooks will begin shortly. Check back after an hour to see sync updates.',
                })
            });
        })
    }

    handleDateChange = (date) => {
        this.setState({
            syncDate: moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
        })
    }

    noIHaveAnIssue = () => {
        const {
            integrationProgress,
            getIntegrationProgress,
            qboDisconnect,
            deactivateIntegration,
            updateAnalytics,
            postPartialIntegrationProgress
        } = this.props;
        const {identity: {integrationId, accountId, businessId, destinationName}, progress} = integrationProgressHelper(integrationProgress);
        if (destinationName === "qbo") {
            qboDisconnect({accountId})
            deactivateIntegration({integrationId, syncValue: false})
        }
        updateAnalytics({
            ...ANALYTICS_BODY[`disconnected_sync_${destinationName}`],
            id: businessId,
        })
        postPartialIntegrationProgress({
            businessId,
            identity: {
                disconnectedProgess: progress,
            },
            progress: integrationProgressConstants.home,
        }).then(() => {
            getIntegrationProgress({businessId})
        })
    }


    verified = () => {
        const {
            getIntegrationProgress,
            postPartialIntegrationProgress,
            integrationProgress: {data: {identity: {businessId}}}
        } = this.props;
        postPartialIntegrationProgress({
            progress: integrationProgressConstants.account_mapping_step_3
        }).then(() => {
            getIntegrationProgress({businessId})
        });
    }

    render() {
        const {
            classes,
            integrationProgress,
            selectedChart,
        } = this.props;
        const step = get(integrationProgress, 'data.progress.step', 0);
        switch (step) {
            case 1: {
                return (
                    <Box className={classes.bottomActions}>
                        <Typography variant="subtitle2">
                            Lets sync a few settled transactions from the last few days.
                        </Typography>
                        <Button
                            disableElevation
                            className={clsx(classes.mediumWideButton)}
                            variant="contained"
                            color="primary"
                            onClick={this.create}
                            disabled={this.state.testSyncActive || !selectedChart || typeof selectedChart === 'string'}
                        >
                            Test Sync
                        </Button>
                    </Box>
                )
            }
            case 2: {
                return (
                    <Box className={classes.bottomActions}>
                        <Typography variant="subtitle2">
                            Do you see some transactions from past few days in QuickBooks Application?
                        </Typography>
                        <Button
                            disableElevation
                            className={clsx(classes.mediumWideButton, classes.marginRight)}
                            variant="outlined"
                            color="primary"
                            onClick={this.noIHaveAnIssue}
                        >
                            No, I found an issue
                        </Button>
                        <Button
                            disableElevation
                            className={clsx(classes.mediumWideButton)}
                            variant="contained"
                            color="primary"
                            onClick={this.verified}
                        >
                            Yes, I verified
                        </Button>
                    </Box>
                )
            }
            case 3: {
                return (
                    <Box className={classes.bottomActions}>
                        <Typography variant="subtitle2">
                            Sync starting from:
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                disableFuture
                                className={classes.picker}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={this.state.syncDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={this.syncData}
                            disabled={this.state.syncDataActive}
                        >
                            Sync Data
                        </Button>
                    </Box>
                )
            }
            default: {
                return (<></>)
            }
        }
    }
}


const mapStateToProps = (state) => ({
    integrationProgress: state.integrationProgress,
});

const mapDispatchToProps = (dispatch) => ({
    createIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(integrationActionName, httpActions.post, payload))),
    deactivateIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(deactivateIntegrationActionName, httpActions.get, payload))),
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    postPartialIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
    poyntSync: (payload) => dispatch(actionDispatcher(dispatchContext(poyntSyncActionName, httpActions.get, payload))),
    qboDisconnect: (payload) => dispatch(actionDispatcher(dispatchContext(qboDisconnectActionName, httpActions.get, payload))),
    toggleSnackbar: (payload) => dispatch(toggleSnackbar(payload)),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(bottomBarStyles)(BottomBar));