import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";

export const qboDisconnectActionName = 'QBO_DISCONNECT';

const qboDisconnectService = {
    get: ({ accountId }) => {
        const url = `/accounting/qbo/${accountId}/disconnect`;
        return Api(url, {
            method: 'GET',
        });
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const QBODisconnectReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_QBO_DISCONNECT_INIT`: {
            return loop({ ...state, status: apiStatus.INIT },
                Cmd.run(qboDisconnectService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(qboDisconnectActionName, httpActions.success, payload)),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(qboDisconnectActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_QBO_DISCONNECT_SUCCESS`: {
            return {
                ...state,
                ...action.payload.data,
                status: apiStatus.SUCCESS,
            };
        }
        default: {
            return state;
        }
    }
};
