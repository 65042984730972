import Home from './Home';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import dashboardStyles from "./homeStyles";
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import {handshakeActionName} from "../../appstate/Handshake.reducer";
import {qboAuthActionName} from "../../appstate/QBOAuth.reducer";
import {analyticsActionName} from "../../appstate/Analytics.reducer";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";
import {chartAccountActionName} from "../../appstate/ChartAccounts.reducer";
import {selectedChartAccountActionName} from "../../appstate/SelectedChartAccount.reducer";
import {identifyActionName} from "../../appstate/Identity.reducer";

const mapStateToProps = (state) => ({
    oauthURL: state.qboAuth,
});

const mapDispatchToProps = (dispatch) => ({
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    getQBOAuth: (payload) => dispatch(actionDispatcher(dispatchContext(qboAuthActionName, httpActions.get, payload))),
    handshake: (payload) => dispatch(actionDispatcher(dispatchContext(handshakeActionName, httpActions.get, payload))),
    postPartialIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
    resetQBOAuth: (payload) => dispatch(actionDispatcher(dispatchContext(qboAuthActionName, httpActions.reset, payload))),
    resetChartAccount: (payload) => dispatch(actionDispatcher(dispatchContext(chartAccountActionName, httpActions.reset, payload))),
    resetSelectedChartAccount: (payload) => dispatch(actionDispatcher(dispatchContext(selectedChartAccountActionName, httpActions.reset, payload))),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
    identify: (payload) => dispatch(actionDispatcher(dispatchContext(identifyActionName, httpActions.post, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyles)(Home));
