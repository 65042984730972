import axios from "axios";
import hostname from "./hostname";
import storage from "../storage";

export async function login() {
  const { data } = await axios.post(`${hostname}login`, {
    email: process.env.REACT_APP_LOGIN_EMAIL,
    password: process.env.REACT_APP_LOGIN_PASSWORD,
    businessId: storage.get("businessId"),
  });
  return data;
}
