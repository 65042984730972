export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
  companyImage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContain: {
    objectFit: 'contain',
    maxWidth: '120px',
    width: '100%',
  },
  centeredImageContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  wideButton: {
    minWidth: '220px',
  },
  mediumWideButton: {
    minWidth: '180px',
  },
  danger: {
    backgroundColor: '#dc3545',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#dc3545',
      color: '#fff',
    },
  },
  centeredContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
  addMarginRight: {
    marginRight: '16px',
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  switchInput: {
    '& span': {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  reportToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  syncStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '0 16px',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  picker: {
    width: '155px',
    marginTop: '8px',
    marginLeft: '8px',
  },
  table: {
    borderTop: '1px solid #ddd',
  },
  successColor: {
    color: '#4caf50',
  }
})