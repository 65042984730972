import {Cmd, loop} from 'redux-loop';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "./generator";
import Api from "../Api";
import {integrationProgressActionName} from "./IntegrationProgress.reducer";
import integrationProgressConstants from "../helpers/integrationProgressConstants";

export const poyntSyncActionName = 'POYNT_SYNC';

const poyntSyncService = {
    get: ({poyntId, date}) => {
        return Api(`/poynt/sync/${poyntId}/${date}`, {
            method: "GET",
        })
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const PoyntSyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_POYNT_SYNC_INIT`: {
            return loop({ ...state, status: apiStatus.INIT},
                Cmd.run(poyntSyncService.get, {
                    successActionCreator: (payload) => actionDispatcher(dispatchContext(poyntSyncActionName, httpActions.success, {
                        previousPayload: action.payload,
                        payload
                    })),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(poyntSyncActionName, httpActions.failed, payload)),
                    args: [action.payload],
                }),
            );
        }
        case `GET_POYNT_SYNC_SUCCESS`: {
            const {businessId}= action.payload.previousPayload;
            const integrationUpdatePayload = {
                progress: {
                    ...integrationProgressConstants.summary
                }
            };
            return loop(
                {
                    ...state,
                    ...action.payload.data,
                    status: apiStatus.SUCCESS
                },
                Cmd.list([
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, integrationUpdatePayload))),
                    Cmd.action(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, {businessId}))),
                ], {
                    sequence: true,
                })
            );
        }
        default: {
            return state;
        }
    }
};
