import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import React from "react";

export default ({active}) => {
    if (active) {
        return (
            <Box mt={5}>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
            </Box>
        );
    }
    return <></>
}