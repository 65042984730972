import React, {Component} from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {ACCOUNT_MAPPING, ANALYTICS_BODY} from '../../helpers/constants';
import SetupHeader from "../../components/SetupHeader/SetupHeader";
import BottomBar from "./BottomBar";
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import {ArrowBack} from "@material-ui/icons";

class AccountMapping extends Component {
    state = {
        selectedChartId: "",
        selectedChart: "",
        gotMid: false,
        gettingMid: false,
        open: false,
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    };

    handleClickYes = () => {
        const {integrationProgress, postPartialIntegrationProgress, getIntegrationProgress, deactivateIntegration, qboDisconnect} = this.props;
        const {identity: {businessId, destinationName, integrationId, accountId}} = integrationProgressHelper(integrationProgress);
        if (destinationName === 'qbo') {
            qboDisconnect({accountId})
            deactivateIntegration({integrationId, syncValue: false})
        }
        postPartialIntegrationProgress({
            businessId,
            progress: integrationProgressConstants.home,
        }).then(() => {
            getIntegrationProgress({businessId})
        })
        this.setState({open: !this.state.open});
    };


    componentDidMount() {
        const {integrationProgress, getChartAccount, getSelectedChartAccount} = this.props;
        const {identity: {accountId, destinationName, mid}} = integrationProgressHelper(integrationProgress);
        if (accountId && destinationName && mid) {
            setTimeout(() => {
                getChartAccount({accountId, destinationName, mid});
            }, 1000);
            getSelectedChartAccount({accountId, destinationName, mid});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {integrationProgress, businessId, selectedChartAccount, getMID, getChartAccount, getSelectedChartAccount, chartAccount} = this.props;
        const {identity: {accountId, destinationName, mid}} = integrationProgressHelper(integrationProgress);
        if (typeof this.state.selectedChart === "string" && selectedChartAccount && typeof selectedChartAccount !== "string") {
            this.setState({
                selectedChartId: selectedChartAccount._id,
                selectedChart: selectedChartAccount,
            })
        }
        if (!this.state.gettingMid && !mid) {
            this.setState({
                gettingMid: true,
            }, () => {
                getMID({businessId})
            })
        }
        if (!this.state.gotMid && mid) {
            this.setState({
                gotMid: true,
            }, () => {
                if (chartAccount.length === 0) {
                    setTimeout(() => {
                        getChartAccount({accountId, destinationName, mid});
                    }, 1000);
                }
                getSelectedChartAccount({accountId, destinationName, mid});
            })
        }
    }

    disconnect = () => {
        const {
            integrationProgress,
            getIntegrationProgress,
            qboDisconnect,
            deactivateIntegration,
            updateAnalytics,
            postPartialIntegrationProgress
        } = this.props;
        const {identity: {integrationId, accountId, businessId}, progress} = integrationProgressHelper(integrationProgress);
        qboDisconnect({accountId})
        deactivateIntegration({integrationId, syncValue: false})
        updateAnalytics({
            ...ANALYTICS_BODY.disconnected_sync_qbo,
            id: businessId,
        })
        postPartialIntegrationProgress({
            businessId,
            identity: {
                disconnectedProgess: progress,
            },
            progress: integrationProgressConstants.disconnect_sync_qbo,
        }).then(() => {
            getIntegrationProgress({businessId})
        })
    }


    checkChartOfAccounts = () => {
        const {integrationProgress, getChartAccount, chartAccount} = this.props;
        const {identity: {accountId}} = integrationProgressHelper(integrationProgress);
        if (chartAccount.length === 0) {
            getChartAccount({accountId})
        }
    }

    backToSummary = () => {
        const {integrationProgress, postPartialIntegrationProgress, getIntegrationProgress} = this.props;
        const {identity: {businessId}} = integrationProgressHelper(integrationProgress);
        postPartialIntegrationProgress({
            businessId,
            progress: integrationProgressConstants.summary,
        }).then(() => {
            getIntegrationProgress({businessId})
        })
    }

    render() {
        const {classes, chartAccount, integrationProgress} = this.props;
        const {identity: {destinationName, fullSyncEnabled}} = integrationProgressHelper(integrationProgress);
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <Box className={classes.content} mb={10}>
                    <SetupHeader/>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Box mb={3}>
                                <Box className={classes.setupHead} mb={1}>
                                    <Typography variant="h6">
                                        Account Mapping
                                    </Typography>
                                    {destinationName === 'qbo' &&
                                    <>
                                        <Box>
                                            {fullSyncEnabled && <Button onClick={this.backToSummary} style={{marginLeft: '5px'}} variant="contained" disableElevation>
                                                <ArrowBack color="primary" /> Back to summary
                                            </Button>}
                                        </Box>
                                    </>
                                    }
                                </Box>
                                <Box my={1}>
                                    <Typography variant="body1">
                                        We have taken a lot of care and effort to map out how your Poynt data will show
                                        up in
                                        QuickBooks. Please refer to the categories listed below to understand the
                                        headers and
                                        what they represent. Please select the appropriate value from the dropdown if
                                        applicable.
                                    </Typography>
                                    <Typography variant="caption">
                                        *Note: Some categories are set to a default to ensure successful syncing.<br/>
                                        *Note: Please wait 20-30 seconds for the Income Account dropdown(Select Chart Account) to populate values.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}><Typography
                                                variant="subtitle2">POYNT</Typography></TableCell>
                                            <TableCell colSpan={2}><Typography
                                                variant="subtitle2">QUICKBOOKS</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CATEGORY</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell>TYPE</TableCell>
                                            <TableCell>FIELD</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            ACCOUNT_MAPPING.map((account) => (
                                                <TableRow key={account.CATEGORY}>
                                                    <TableCell component="th" scope="row">
                                                        <Typography variant="subtitle2">
                                                            {account.CATEGORY}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">
                                                            {account.DESCRIPTION}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="caption">
                                                            {account.TYPE}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {account.DROPDOWN && (
                                                            <FormControl style={{minWidth: '200px', maxWidth: '300px'}}
                                                                         disabled={
                                                                             (this.props.selectedChartAccount !== undefined &&
                                                                                 this.props.selectedChartAccount !== '') ||
                                                                             (this.state.selectedChartId !== '' && this.props.selectedChartAccount === '')
                                                                         }>
                                                                <Select
                                                                    displayEmpty
                                                                    value={chartAccount.length > 0 ? this.state.selectedChartId : ''}
                                                                    labelId="chart-acount-select-label"
                                                                    onOpen={this.checkChartOfAccounts}
                                                                    onChange={(val) => {
                                                                        this.setState({
                                                                            selectedChartId: val.target.value,
                                                                            selectedChart: chartAccount.find((ac) => ac._id === val.target.value),
                                                                        });
                                                                    }}
                                                                >
                                                                    <MenuItem value="">
                                                                        <Typography variant="caption">Select Chart
                                                                            Account</Typography>
                                                                    </MenuItem>
                                                                    {chartAccount.filter((account) => account.type === 'Income').map((account) => {
                                                                        return (<MenuItem key={account._id}
                                                                                          value={account._id}>{account.name}</MenuItem>)
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                        {!account.DROPDOWN && (
                                                            <Typography variant="caption">
                                                                {account.FIELD}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Box>

                            {destinationName === 'qbo' && <Box className={classes.centeredContent} mt={3}>
                                <Button disableElevation className={clsx(classes.wideButton, classes.danger)}
                                        onClick={this.disconnect}>Disconnect</Button>
                            </Box>}
                        </CardContent>
                    </Card>
                </Box>
                <BottomBar selectedChart={this.state.selectedChart}/>
            </div>
        );
    }
}

AccountMapping.propTypes = {};

export default AccountMapping;
