import {Cmd, loop} from "redux-loop";
import {actionDispatcher, apiStatus, dispatchContext, httpActions,} from "./generator";
import Api from "../Api";

export const activateIntegrationActionName = "ACTIVATE_INTEGRATION";

const activateIntegrationProgressService = {
    post: ({accountId}) => {
        const url = `/integration/activate-integration/${accountId}?active=true`;
        return Api(url, {
            method: "POST",

        });
    },
};

const initialState = {
    status: apiStatus.INIT,
};

export const ActivateIntegrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case `POST_ACTIVATE_INTEGRATION_INIT`: {
            return loop(
                {...state, status: apiStatus.INIT},
                Cmd.run(activateIntegrationProgressService.post, {
                    successActionCreator: (payload) =>
                        actionDispatcher(
                            dispatchContext(activateIntegrationActionName, httpActions.postSuccess, payload)
                        ),
                    failActionCreator: (payload) => actionDispatcher(dispatchContext(activateIntegrationActionName, httpActions.postFailed, payload)),
                    args: [action.payload],
                })
            );
        }
        case `POST_ACTIVATE_INTEGRATION_SUCCESS`: {
            window.opener.postMessage("oauth-done", "*");
            window.close();
            return state;
        }
        default: {
            return state;
        }
    }
};
