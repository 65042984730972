import React from 'react';
import ReactDOM from 'react-dom';


import {MuiThemeProvider} from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import store from './store';
import './fonts.scss';
import './index.scss';
import theme from './theme';
import {HashRouter} from "react-router-dom";
import Routes from "./screens/Routes";

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <MuiThemeProvider theme={theme}>
                <Routes/>
            </MuiThemeProvider>
        </HashRouter>
    </Provider>,
    rootElement,
);
