import React from 'react';
import {actionDispatcher, apiStatus, dispatchContext, httpActions} from "../appstate/generator";
import {integrationProgressActionName} from "../appstate/IntegrationProgress.reducer";
import {connect} from "react-redux";
import parseQueryParams from "../helpers/parseQueryParams";
import Loader from "./Loader";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {toggleSnackbar} from "../appstate/Snackbar.reducer";

import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import wrapperHOCStyles from "./wrapperHOCStyles";
import integrationProgressHelper from "../helpers/integrationProgressHelper";
import storage from '../storage';
import PageHeightMessager from '../helpers/pageHeightMessager';

const WrapperHoc = (WrappedComponent) => {
    class HOC extends React.Component {
      state = {};

      componentDidMount() {
        const { getIntegrationProgress } = this.props;
        let savedBusinessId;
        const { businessId, gdphub } = parseQueryParams();
        savedBusinessId = businessId;
        if (!savedBusinessId) {
          savedBusinessId = storage.get('businessId');
        } else {
          storage.set('businessId', savedBusinessId);
        }
        this.setState(
          {
            businessId: savedBusinessId,
          },
          () => {
            if (savedBusinessId) {
              const { businessId } = this.state;
              getIntegrationProgress({ businessId });
            }
          }
        );
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkRedirects();
        const pageHeight = new PageHeightMessager();
        pageHeight.init();
      }

      toggleSnackbar = () => {
        const { toggleSnackbar } = this.props;
        toggleSnackbar({
          open: false,
          message: '',
        });
      };

      checkRedirects = () => {
        const { integrationProgress, location } = this.props;
        const { businessId } = this.state;
        const { gdphub } = parseQueryParams();
        if (
          integrationProgress.status === apiStatus.SUCCESS &&
          !integrationProgress.data &&
          location.pathname !== '/'
        ) {
          this.props.history.push({
            pathname: '/',
            search: `?businessId=${businessId}${gdphub === 'true' ? `&gdphub=${gdphub}` : ''}`,
          });
        }
        if (integrationProgress?.data?.progress?.current) {
          const current = integrationProgress.data.progress.current;
          if (current !== location.pathname) {
            this.props.history.push({
              pathname: current,
              search: `?businessId=${businessId}${gdphub === 'true' ? `&gdphub=${gdphub}` : ''}`,
            });
          }
        }
      };

      render() {
        const { integrationProgress, history, location, snackbar, classes } = this.props;
        const {
          identity: { fullSyncEnabled },
        } = integrationProgressHelper(integrationProgress);
        const { businessId } = this.state;
        const { gdphub } = parseQueryParams();
        if (gdphub === 'true') {
          const body = document.getElementById('sp-accounting');
          body.style.backgroundColor = '#fff';
          body.style.overflow = 'hidden';
        } else {
          const body = document.getElementById('sp-accounting');
          body.style.backgroundColor = '#F2F2F2';
        }

        let component;
        if (integrationProgress.status === apiStatus.INIT) {
          component = (
            <>
              {!businessId && (
                <i>
                  <strong>Please call with a valid business id</strong>
                </i>
              )}
              <Loader active={integrationProgress.status === apiStatus.INIT} />
            </>
          );
        } else {
          component = (
            <WrappedComponent
              integrationProgress={integrationProgress}
              businessId={businessId}
              fullSyncEnabled={fullSyncEnabled}
              history={history}
              location={location}
            />
          );
        }
        return (
          <Container
            maxWidth={gdphub === 'true' ? false : 'lg'}
            style={{
              padding: gdphub === 'true' ? '8px' : '0 24px',
              backgroundColor: gdphub === 'true' ? '#fff' : '#F2F2F2',
            }}
          >
            <CssBaseline />
            <Box>
              {gdphub !== 'true' && (
                <Box py={2}>
                  <Typography variant="h5">Accounting Sync</Typography>
                </Box>
              )}
              {snackbar.open && (
                <Snackbar
                  style={{ top: '10%' }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={snackbar.open}
                  message={snackbar.message}
                  autoHideDuration={snackbar.autoHideDuration}
                  onClose={this.toggleSnackbar}
                >
                  <Alert
                    className={classes[snackbar.severity]}
                    action={
                      <IconButton onClick={this.toggleSnackbar} size="small">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    severity={snackbar.severity}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
              )}
              {component}
            </Box>
          </Container>
        );
      }
    }

    const mapStateToProps = (state) => ({
        integrationProgress: state.integrationProgress,
        snackbar: state.snackbar,
    });

    const mapDispatchToProps = (dispatch) => ({
        toggleSnackbar: (payload) => dispatch(toggleSnackbar(payload)),
        getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.get, payload))),
    });

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(wrapperHOCStyles)(HOC)));
}

export default WrapperHoc;
