export default [
  {
    step: 0,
    title: 'Check the preferences in your QuickBooks Desktop Application.',
    description: 'Please ensure your sound is on and you are on the computer QuickBooks Desktop is installed on.',
    actionLabel: 'Next',
    videoId: 'OFlCH9vOUH4',
  },
  {
    step: 1,
    title: 'Download QuickBooks Web Connector to your computer.',
    description: '',
    actionLabel: 'Download QBWC File',
    videoId: 'sUHohrLTlgI',
  },
  {
    step: 2,
    title: 'Upload the Web Connector file into the QuickBooks Desktop Application.',
    actionLabel: 'Next',
    videoId: 'q46Z5SWZ9dU',
  },
  {
    step: 3,
    title: 'Set up QuickBooks Web Connector:',
    actionLabel: 'Continue',
    videoId: 'KDJuoafkoUs',
  },
];
