import React from 'react';
import get from 'lodash/get';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SetupHeader from '../../components/SetupHeader/SetupHeader';
import QBDSteps from './steps/QBDSteps';
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import {ANALYTICS_BODY} from "../../helpers/constants";
import Link from "@material-ui/core/Link";
import downloadFile from "../../helpers/downloadFile";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Transition from "../../components/Transition";
import DialogContent from "@material-ui/core/DialogContent";


class QBDSetup extends React.Component {
    state = {
        gettingMid: false,
        open: false,
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    };

    handleClickYes = () => {
        const {integrationProgress, postIntegrationProgress, getIntegrationProgress, updateAnalytics} = this.props;
        const {identity: {businessId}, progress} = integrationProgressHelper(integrationProgress);
        const activeStep = get(progress, 'step', 0);
        updateAnalytics({
            ...ANALYTICS_BODY[`qbd_setup_cancelled_at_step_${activeStep + 1}`],
            id: businessId,
        });
        postIntegrationProgress({
            businessId,
            requestBody: {
                data: {
                    businessId,
                    progress: integrationProgressConstants.home,
                }
            }
        }).then(() => {
            getIntegrationProgress({businessId})
        })
        this.setState({open: !this.state.open});
    };

    componentDidMount() {
        const {getChartAccount, integrationProgress} = this.props;
        const {identity: {accountId}} = integrationProgressHelper(integrationProgress);
        if (accountId) {
            getChartAccount({accountId});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {integrationProgress, getMID} = this.props;
        const {identity: {businessId, mid}} = integrationProgressHelper(integrationProgress);
        if (!mid && !this.state.gettingMid) {
            this.setState({
                gettingMid: true,
            }, () => {
                getMID({businessId});
            })
        }
    }


    qbdActions = () => {
        const {
            integrationProgress,
            postPartialIntegrationProgress,
            getIntegrationProgress,
            updateAnalytics,
            chartAccount,
            getChartAccount,
            toggleSnackbar,
        } = this.props;
        const {identity: {businessId, accountId}} = integrationProgressHelper(integrationProgress);
        return {
            step0: () => {
                updateAnalytics({
                    ...ANALYTICS_BODY.qbd_setup_1,
                    id: businessId,
                });
                postPartialIntegrationProgress({
                    businessId,
                    progress: integrationProgressConstants.qbd_setup_1,
                }).then(() => {
                    getIntegrationProgress({businessId})
                })
            },
            step1: () => {
                updateAnalytics({
                    ...ANALYTICS_BODY.qbd_setup_2,
                    id: businessId,
                });
                this.buildFile();
            },
            step2: () => {
                updateAnalytics({
                    ...ANALYTICS_BODY.qbd_setup_3,
                    id: businessId,
                });
                postPartialIntegrationProgress({
                    businessId,
                    progress: integrationProgressConstants.qbd_setup_3,
                }).then(() => {
                    getIntegrationProgress({businessId})
                })
            },
            step3: () => {
                if (chartAccount.length > 0) {
                    updateAnalytics({
                        ...ANALYTICS_BODY.qbd_setup_4,
                        id: businessId
                    });
                    postPartialIntegrationProgress({
                        businessId,
                        progress: integrationProgressConstants.account_mapping_step_1,
                    }).then(() => {
                        getIntegrationProgress({businessId})
                    })
                } else {
                    toggleSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Do the QuickBooks setup and wait for the process to complete in QuickBooks for the sync to kick off.',
                    });
                    getChartAccount({accountId});
                }
            }
        }
    }

    qbdStepper = (step) => {
        const {
            integrationProgress,
            postPartialIntegrationProgress,
            getIntegrationProgress,
        } = this.props;
        const {identity: {businessId}} = integrationProgressHelper(integrationProgress);
        postPartialIntegrationProgress({
            businessId,
            progress: integrationProgressConstants[`qbd_setup_${step}`],
        }).then(() => {
            getIntegrationProgress({businessId})
        })
    }

    customQBDContent = () => {
        const {integrationProgress, classes} = this.props;
        const {identity: {qbdFilePassword}} = integrationProgressHelper(integrationProgress);
        return {
            step2: (<Typography gutterBottom
                                variant="subtitle1"
                                component="p"
                                style={{color: '#808080'}}>
                If your download didn't start, <Link style={{cursor: 'pointer'}}
                                                     onClick={this.tryAgain}>try
                again</Link>
            </Typography>),
            step3: (<>
                <>
                    <ul className={classes.list}>
                        <li>
                            Authorize Check
                        </li>
                        <li>
                            Auto-Run
                        </li>
                        <li>
                            Enter this password
                        </li>
                        <li>
                            Click "Update Selected"
                        </li>
                    </ul>
                </>
                <br/>
                <Typography
                    className={classes.password}
                    variant="h6" component="span">{qbdFilePassword}</Typography>
                <Typography gutterBottom
                            variant="subtitle1"
                            component="p"
                            style={{color: '#808080'}}>
                    If your download didn't start, <Link style={{cursor: 'pointer'}}
                                                         onClick={this.tryAgain}>try
                    again</Link>
                </Typography>
            </>)
        }
    }

    buildFile = () => {
        const password = Math.random().toString(36).replace('0.', '');
        const {integrationProgress, downloadQBDFile, postPartialIntegrationProgress, getIntegrationProgress} = this.props;
        const {identity: {mid, businessId, qbdFilePassword, xmlFileData}} = integrationProgressHelper(integrationProgress);
        if (xmlFileData) {
            postPartialIntegrationProgress({
                businessId,
                progress: integrationProgressConstants.qbd_setup_2,
            }).then(() => {
                getIntegrationProgress({businessId})
            })
            this.tryAgain();
            return;
        }
        const pwd = qbdFilePassword ? qbdFilePassword : password;
        const fileInput = {
            "companyName": mid,
            "username": mid,
            password: pwd,
            businessId,
        }
        downloadQBDFile(fileInput)
    }

    tryAgain = () => {
        const {integrationProgress} = this.props;
        const {identity: {mid, xmlFileData}} = integrationProgressHelper(integrationProgress);
        if (mid && xmlFileData) {
            downloadFile(new Blob([xmlFileData], {type: 'text/plain'}), mid);
        }
        return null;
    }


    render() {
        const {integrationProgress, classes} = this.props;
        const {progress, identity: {xmlFileData}} = integrationProgressHelper(integrationProgress);
        const activeStep = get(progress, 'step', 0);

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <Box className={classes.content} mb={10}>
                    <SetupHeader/>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Box className={classes.setupHead} mb={3}>
                                <Typography variant="h6">
                                    Setting-up QuickBooks Desktop
                                </Typography>
                                <Button onClick={this.handleClickOpen} variant="contained" disableElevation>
                                    Cancel setup
                                </Button>
                                <Dialog
                                    maxWidth="sm"
                                    open={this.state.open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => {
                                    }}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog">Are you sure?</DialogTitle>
                                    <DialogContent>
                                        <Typography variant="subtitle2">
                                            You are about to cancel and exit the integration setup process.
                                            Are you sure, you want to proceed ?
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClickOpen}>
                                            No, continue setup
                                        </Button>
                                        <Button onClick={this.handleClickYes} variant="contained" color="primary">
                                            Yes, exit setup
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            <QBDSteps
                                qbdStepper={this.qbdStepper}
                                allStepsActive={xmlFileData !== undefined}
                                activeStep={activeStep}
                                onActiveStepChange={this.onActiveStepChange}
                                customQBDContent={this.customQBDContent}
                                qbdActions={this.qbdActions}/>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        )
    }
}


QBDSetup.propTypes = {};

export default QBDSetup;
