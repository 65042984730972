import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {qbdSetupConstants} from '../../helpers/constants';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginBottom16: {
        marginBottom: '16px',
    },
    companyImage: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    imageContain: {
        objectFit: 'contain',
        maxWidth: '120px',
        width: '100%',
        maxheight: '55px',
    },
    centeredImageContent: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    centeredContent: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    spaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
    },
    linksContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const SetupHeader = () => {
    const preventDefault = (event) => event.preventDefault();
    const classes = useStyles();
    return (
        <Card className={classes.marginBottom16}>
            <CardContent>
                <Grid container spacing={0} className={classes.spaceBetween}>
                    <Grid item xs={2} className={classes.centeredImageContent}>
                        <div className={classes.companyImage}>
                            <img
                                className={classes.imageContain}
                                src={qbdSetupConstants.intuitLogo}
                                alt="Intuit logo"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} className={classes.linksContainer}>
                        <Box>
                            <Link
                                underline="always"
                                href="https://support.poynt.com/hc/en-us/articles/360045791933-Integrating-QuickBooks-Online-with-GoDaddy-Poynt"
                                rel="noreferrer"
                                target="_blank"
                            >
                                View step-by-step instruction
                            </Link>
                        </Box>
                        <Box>
                            <Link
                                underline="always"
                                href="https://support.poynt.com/hc/en-us/articles/360044754333"
                                rel="noreferrer"
                                target="_blank"
                            >
                                FAQs
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

SetupHeader.propTypes = {};

export default SetupHeader;
