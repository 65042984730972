import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { install } from 'redux-loop';

import { applyMiddleware, compose, createStore } from 'redux';

import reducers from './appstate';

const enhancer = compose(
  applyMiddleware(thunk),
  install(),
);

const store = createStore(
  reducers,
  {},
  composeWithDevTools(enhancer),
);

export default store;
