import React, {Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ANALYTICS_BODY, qbdSetupConstants} from '../../helpers/constants';
import integrationProgressHelper from "../../helpers/integrationProgressHelper";
import moment from "moment";
import integrationProgressConstants from "../../helpers/integrationProgressConstants";
import QBLogo from '../../assets/images/Intuit_QuickBooks_logo.svg'

class Summary extends Component {
    state = {
        syncDateEdited: false,
        syncValue: null,
        syncDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    }

    componentDidMount() {
        this.refreshSummary();
        this.latestSync();
    }

    handleDateChange = (date) => {
        this.setState({
            syncDate: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
            lastSyncDate: moment(date).format("MM/DD/YYYY"),
            lastSyncTime: moment(date).format("HH:mm")
        })
    }

    syncData = () => {
        const {
            updateAnalytics,
            poyntSync,
            integrationProgress
        } = this.props;
        const {identity: {poyntId, businessId, destinationName}} = integrationProgressHelper(integrationProgress);
        poyntSync({poyntId, date: this.state.syncDate, businessId});
        updateAnalytics({
            ...ANALYTICS_BODY[`${destinationName}_enforce_sync`],
            id: businessId,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.syncDateEdited && this.props.poyntLatestSyncData.lastSyncedAt) {
            this.setState({
                syncDateEdited: true,
                syncValue: this.props.poyntLatestSyncData.active,
                syncDate: this.props.poyntLatestSyncData.lastSyncedAt,
                lastSyncDate: moment(this.props.poyntLatestSyncData.lastSyncedAt).format("MM/DD/YYYY"),
                lastSyncTime: moment(this.props.poyntLatestSyncData.lastSyncedAt).format("HH:mm")
            })
        }
    }

    refreshSummary = () => {
        const {integrationProgress, integrationSummary} = this.props;
        const {identity: {integrationId}} = integrationProgressHelper(integrationProgress);
        if (integrationId) {
            integrationSummary({integrationId});
        }
    }

    latestSync = () => {
        const {integrationProgress, poyntLatestSync} = this.props;
        const {identity: {poyntId}} = integrationProgressHelper(integrationProgress);
        if (poyntId) {
            poyntLatestSync({poyntId});
        }
    }

    deactivateSync = () => {
        const {integrationProgress, deactivateSync} = this.props;
        const {identity: {businessId}} = integrationProgressHelper(integrationProgress);
        if (businessId) {
            deactivateSync({businessId, syncValue: this.state.syncValue});
        }
    }

    deactivateIntegration = () => {
        const {integrationProgress, deactivateIntegration} = this.props;
        const {identity: {integrationId}} = integrationProgressHelper(integrationProgress);
        if (integrationId) {
            deactivateIntegration({integrationId, syncValue: this.state.syncValue});
        }
    }

    editSettings = () => {
        const {integrationProgress, postIntegrationProgress, getIntegrationProgress} = this.props;
        const {identity: {businessId}} = integrationProgressHelper(integrationProgress);
        postIntegrationProgress({
            businessId,
            identity: {
                fullSyncEnabled: true,
            },
            progress: integrationProgressConstants.account_mapping_step_4,
        }).then(() => {
            getIntegrationProgress({businessId})
        })
    }

    updateSync = () => {
        this.setState({
            syncValue: !this.state.syncValue,
        }, () => {
            this.deactivateIntegration();
            this.deactivateSync();
        })
    }

    render() {
        const {classes, integrationSummaryData, integrationProgress} = this.props;
        const {identity: {destinationName}} = integrationProgressHelper(integrationProgress);
        return (
            <Box className={classes.root}>
                <CssBaseline/>
                <Box className={classes.content} mb={10}>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Grid container spacing={0} className={classes.spaceBetween}>
                                <Grid item xs={2} className={classes.centeredImageContent}>
                                    <div className={classes.companyImage}>
                                        <img
                                            className={classes.imageContain}
                                            src={qbdSetupConstants.intuitLogo}
                                            alt="Intuit logo"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={8} className={classes.linksContainer}>
                                    <Box className={classes.syncStatus}>
                                        <Box>
                                            <Typography variant="subtitle2">
                                                SYNC ACTIVE:
                                                {
                                                    this.state.syncValue && <Switch
                                                        checked={true}
                                                        onChange={this.updateSync}
                                                        name="syncActive"
                                                        color="primary"
                                                        inputProps={{'aria-label': 'SYNC ACTIVE'}}
                                                    />
                                                }
                                                {
                                                    !this.state.syncValue && <Switch
                                                        checked={false}
                                                        onChange={this.updateSync}
                                                        name="syncActive"
                                                        color="primary"
                                                        inputProps={{'aria-label': 'SYNC ACTIVE'}}
                                                    />
                                                }

                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption">
                                                {this.state.lastSyncTime && (<>Last Transaction synced
                                                    at {this.state.lastSyncTime} on {this.state.lastSyncDate}</>)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {destinationName === 'qbo' &&
                                        <Button variant="outlined" color="secondary" onClick={this.editSettings}>
                                            Edit Settings
                                        </Button>}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.marginBottom16}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={3} mb={3}>
                                    <Typography variant="h6">
                                        Recently Synced Reports
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} className={classes.reportToolbar}>
                                    <Box className={classes.datePickerContainer} mr={2}>
                                        <Typography variant="subtitle2">
                                            Sync starting from :
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                disableFuture
                                                className={classes.picker}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.syncDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box>
                                        <Button
                                            className={classes.addMarginRight}
                                            variant="contained"
                                            disableElevation
                                            color="primary"
                                            onClick={this.syncData}
                                        >
                                            Sync
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            color="secondary"
                                            onClick={() => {
                                                this.refreshSummary();
                                                this.latestSync();
                                            }}
                                        >
                                            Refresh
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Box>
                                {integrationSummaryData.length > 0 &&
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>APP</TableCell>
                                            <TableCell>STARTED AT</TableCell>
                                            <TableCell>COMPLETED AT</TableCell>
                                            <TableCell>DATE RANGE</TableCell>
                                            <TableCell>SYNCED</TableCell>
                                            <TableCell>STATUS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {integrationSummaryData.map(({
                                                                         systemFinishAt,
                                                                         systemStartedAt,
                                                                         dateRangeStart,
                                                                         dateRangeEnd,
                                                                         _id,
                                                                         counts,
                                                                     }) => (
                                            <TableRow key={_id}>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="subtitle2">
                                                        <img src={QBLogo} alt="qbo logo" width="35px"/>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="subtitle2">
                                                        {moment.utc(systemStartedAt).format('hh:mma')}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {moment.utc(systemStartedAt).format('MM/DD/YYYY')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        {moment.utc(systemFinishAt).format('hh:mma')}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {moment.utc(systemFinishAt).format('MM/DD/YYYY')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        {moment.utc(dateRangeStart).format('MM/DD/YYYY')} - {moment.utc(dateRangeEnd).format('MM/DD/YYYY')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="caption">
                                                        {counts.transaction} new
                                                        transaction{`${counts.transaction > 1 ? 's' : ''}`}
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant="caption">
                                                        {counts.refund} new refund{`${counts.refund > 1 ? 's' : ''}`}
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant="caption">
                                                        {counts.order} new order{`${counts.order > 1 ? 's' : ''}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" className={classes.successColor}>
                                                        Completed
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>}
                                {integrationSummaryData.length === 0 && (
                                    <Box style={{textAlign: 'center'}} my={6}>
                                        <Typography variant="h6">Sync in process. Please wait for a while</Typography>
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        );
    }
}

Summary.propTypes = {};

export default Summary