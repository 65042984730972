import get from 'lodash/get';
import Summary from './Summary';
import {actionDispatcher, dispatchContext, httpActions} from "../../appstate/generator";
import {integrationSummaryActionName} from "../../appstate/IntegrationSummary.reducer";
import {poyntLatestSyncActionName} from "../../appstate/PoyntLatestSync.reducer";
import {deactivateSyncActionName} from "../../appstate/DeactivateSync.reducer";
import {deactivateIntegrationActionName} from "../../appstate/DeactivateIntegration.reducer";
import {poyntSyncActionName} from "../../appstate/PoyntSync.reducer";
import {analyticsActionName} from "../../appstate/Analytics.reducer";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import summaryStyles from "./summaryStyles";
import {integrationProgressActionName} from "../../appstate/IntegrationProgress.reducer";

const mapStateToProps = (state) => ({
    integrationProgress: state.integrationProgress,
    poyntLatestSyncData: state.poyntLatestSync,
    integrationSummaryData: get(state, 'integrationSummary.data', []),
});

const mapDispatchToProps = (dispatch) => ({
    integrationSummary: (payload) => dispatch(actionDispatcher(dispatchContext(integrationSummaryActionName, httpActions.get, payload))),
    poyntLatestSync: (payload) => dispatch(actionDispatcher(dispatchContext(poyntLatestSyncActionName, httpActions.get, payload))),
    deactivateSync: (payload) => dispatch(actionDispatcher(dispatchContext(deactivateSyncActionName, httpActions.get, payload))),
    deactivateIntegration: (payload) => dispatch(actionDispatcher(dispatchContext(deactivateIntegrationActionName, httpActions.get, payload))),
    poyntSync: (payload) => dispatch(actionDispatcher(dispatchContext(poyntSyncActionName, httpActions.get, payload))),
    updateAnalytics: (payload) => dispatch(actionDispatcher(dispatchContext(analyticsActionName, httpActions.post, payload))),
    getIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.getSilently, payload))),
    postIntegrationProgress: (payload) => dispatch(actionDispatcher(dispatchContext(integrationProgressActionName, httpActions.postPartialCustom, payload))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(summaryStyles)(Summary));
